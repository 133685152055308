<template>
  <div
    class="tab-pane fade col-md-12"
    id="tenantservice"
    role="tabpanel"
    aria-labelledby="tenantservice-tab`"
  >
    <div class="row" v-if="servicos != ''">
      <div class="col-md-4 box-categories">
        <div class="row align-items-center">
          <div class="col-md-12 form-group form-outline pt-3">
            <div class="ml-1 d-flex align-items-center">
              <div class="mr-2 field-checkbox">
                <input
                  class="field"
                  type="checkbox"
                  id="selectAllCategory"
                  name="selectAllCategory"
                  value="true"
                  @change="selecAllCategory($event.target.checked)"
                  :checked="categoryId.length == categorias.length"
                />
                <label for="selectAllCategory" class="description"></label>
              </div>
              <p
                @click="filterCat(null), $emit('getCheckBox', $event.target)"
                class="label_default _checkbox mb-2 text-right mr-1 "
              >
                Todas Categorias
              </p>
            </div>
          </div>
          <div class="over Flipped-Right">
            <div class="Flipped-Left">
              <div
                class="col-md-12 mb-1 pt-3 box_category"
                v-for="(item, index) in categorias"
                :key="index"
              >
                <ValidationProvider
                  tag="div"
                  class="d-flex align-items-center"
                  v-slot="{ errors, ariaMsg, classes, validate }"
                  :name="`categorias${index}`"
                >
                  <div class="mr-2 field-checkbox">
                    <input
                      type="checkbox"
                      class="field"
                      :value="parseInt(item.id)"
                      :id="`cb${index}`"
                      :name="`cb${index}`"
                      v-model="categoryId"
                      checked
                      @change="
                        selectCategory($event.target.value), validate($event)
                      "
                    />
                    <label class="description" :for="`cb${index}`"></label>
                  </div>
                  <p
                    class="label_default _checkbox _checkbox_blue mb-2 "
                    :class="classes"
                    @click="filterCat(item.id)"
                  >
                    {{ item.name }}
                  </p>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="row align-items-center">
          <div class="col-md-12 mb-2">
            <div class="d-flex align-items-center">
              <inputFilter @filterCp="filterCp" id="searchTenantService" />
            </div>
          </div>
        </div>
        <div class="row align-items-center over">
          <div
            class="col-md-6 texto mb-1"
            v-for="(item, index) in resultQuery"
            :key="index"
          >
            <div class="d-flex align-items-center">
              <div class="mr-2 field-checkbox">
                <input
                  class="field"
                  type="checkbox"
                  :id="`item${index}`"
                  :name="`item${index}`"
                  :value="{
                    serviceName: item.serviceName,
                    serviceId: item.serviceId,
                    categoryId: item.categoryId
                  }"
                  v-model="selectItem"
                  checked
                  @change="selectService($event, index)"
                />
                <label class="description" :for="`item${index}`"></label>
              </div>
              <p
                class="label_default _checkbox_itens mb-2"
                @click="$emit('getCheckBox', $event.target)"
              >
                {{ item.serviceName }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-md-10 mx-auto">
        <p class="my-5 text-center w-100" style="font-size:16px;">
          Ainda não existe serviço cadastrado, para continuar cadastre um
          serviço
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import inputFilter from "@/components/filters/inputFilter.vue";

export default {
  components: {
    inputFilter
  },
  props: ["services"],
  data() {
    return {
      categoryId: [],
      selectItem: [],
      filter: null,
      filterCatId: null
    };
  },
  mounted() {
    if (this.services.length) {
      this.getServiceArray();
    }
  },
  methods: {
    selectCategory(eventId) {
      var currentValue = this.categoryId.findIndex(e => e == eventId);

      if (currentValue > -1) {
        if (this.categoryId != "") {
          this.servicos.forEach((item, index) => {
            if (item.categoryId == eventId) {
              this.selectItem.push({
                serviceName: item.serviceName,
                serviceId: item.serviceId,
                categoryId: parseInt(item.categoryId)
              });
            }
          });
        }
      } else {
        var array = [];
        this.selectItem.forEach((item, index) => {
          if (item.categoryId != eventId) {
            array.push(item);
          }
        });
        this.selectItem = array;
      }
    },
    selectService(e, i) {
      var category = e.target._value.categoryId;
      var compara = this.categoryId.includes(category);
      if (!compara) {
        this.categoryId.push(category);
      } else {
        if (this.selectItem) {
          var currentValue = this.selectItem.findIndex(
            e => e.categoryId == category
          );
          if (currentValue < 0) {
            var array = [];
            this.categoryId.forEach((element, index) => {
              if (element != category) {
                array.push(element);
              }
            });
            this.categoryId = array;
          }
        }
      }
    },
    convertArray() {
      var array = [];
      this.selectItem.forEach(element => {
        array.push({
          serviceName: element.serviceName,
          serviceId: element.serviceId
        });
      });
      this.$emit("update:services", array);
    },
    getServiceArray() {
      var array = [];

      this.categorias.forEach(element => {
        element.listService.forEach(obj => {
          this.services.forEach(item => {
            if (item.serviceId == obj.id) {
              this.selectItem.push({
                serviceName: item.serviceName,
                serviceId: item.serviceId,
                categoryId: parseInt(element.id)
              });

              array.push(parseInt(element.id));
            }
          });
        });
      });
      this.categoryId = [...new Set(array)];
    },
    getAtiveColor(val, index) {
      var color = "";
      var compara;
      if (index == 0) {
        compara = this.categoryId.includes(val);

        if (compara) {
          color = "color:#2474b2";
        }
      } else {
        compara = this.selectItem.some(item => item.serviceId == val);
        if (compara) {
          color = "color:#2474b2";
        }
      }
      return color;
    },
    selecAllCategory(ev) {
      if (ev) {
        var array = [];
        this.categorias.forEach(element => {
          array.push(parseInt(element.id));
        });
        this.selectItem = this.servicos;
        this.categoryId = [...new Set(array)];
      } else {
        this.selectItem = [];
        this.categoryId = [];
      }
    },
    filterCp(event) {
      this.filter = `${event.target.value}`;
    },
    filterCat(id) {
      this.filterCatId = id;
    }
  },
  watch: {
    selectItem: function() {
      if (this.selectItem == "") {
        this.categoryId = [];
      }
    },
    categoryId: function() {},
    obj: function() {
      if (this.services == "") {
        this.categoryId = [];
        this.selectItem = [];
      }
    }
  },
  computed: {
    categorias() {
      return this.$store.state.servicos.servicosCategoria;
    },
    servicos() {
      var array = [];
      if (this.categorias) {
        this.categorias.forEach(element => {
          element.listService.forEach(item => {
            var obj = {
              serviceName: item.name,
              serviceId: item.id,
              categoryId: parseInt(element.id)
            };

            array.push(obj);
          });
        });
      }
      return array;
    },
    resultQuery() {
      if (this.filter) {
        this.filterCatId = null;
        return this.servicos.filter(item => {
          return this.filter
            .toLowerCase()
            .split(" ")
            .every(v => item.serviceName.toLowerCase().includes(v));
        });
      } else if (this.filterCatId) {
        return this.servicos.filter(v => v.categoryId == this.filterCatId);
      } else {
        return this.servicos;
      }
    }
  }
};
</script>
<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
@media (max-width: 770px) {
  .over {
    max-width: 500px;
  }
}
@media (max-width: 550px) {
  .over {
    max-width: 350px;
  }
}
@media (max-width: 390px) {
  .over {
    max-width: 290px;
  }
}
@media (max-width: 335px) {
  .over {
    max-width: 200px;
  }
}

p.failed {
  color: red !important;
}

.over {
  max-height: 373px;
  overflow-y: auto;
}

.box-categories {
  background-color: #f9f9f9;
}

.label_default._checkbox_blue:hover {
  color: #f38235;
}
.box_category {
  padding: 0 auto;
}
.box_category:hover {
  background: #daebf8;
}
</style>
