<template>
  <div
    class="tab-pane fade col-md-12"
    id="tenantinformation"
    role="tabpanel"
    aria-labelledby="tenantinformation-tab"
  >
    <div class="row">
      <ValidationProvider
        tag="div"
        class="col-md-5 mb-1 form-group form-outline"
        v-slot="{ errors, ariaMsg, classes }"
        name="Nome da Unidade"
        :rules="{
          required: true,
          max: 50,
          regex: /[a-zA-Z\u00C0-\u00FF ]+/i,
          validaName: true,
          validaOldname: true
        }"
      >
        <label class="mb-1 label_default" :class="classes">
          Nome da Unidade
        </label>
        <input
          class="form-control input_default"
          :class="classes"
          :value="name"
          maxlength="50"
          @input="$emit('update:name', $event.target.value)"
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="col-md-2 mb-1 form-group form-outline"
        v-slot="{ errors, ariaMsg, classes }"
        rules="required|max:4"
        name="Sigla"
      >
        <label class="mb-1 label_default" :class="classes">Sigla</label>
        <input
          class="form-control input_default"
          :class="classes"
          :value="reference"
          maxlength="4"
          @input="$emit('update:reference', $event.target.value)"
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>

      <ValidationProvider
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        class="col-md-5 mb-1 form-group form-outline"
        rules="required"
        name="Nome do Franqueado"
      >
        <label class="mb-1 label_default" :class="classes"
          >Nome do Franqueado</label
        >

        <multiselect
          :class="classes"
          v-model="selectUsers"
          :options="listUsers"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :show-labels="false"
          :searchable="true"
          :maxHeight="100"
          :limit="1"
          :limit-text="limitText"
          placeholder="Selecione o franqueado"
          label="displayName"
          track-by="displayName"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="selectUsers.length && !isOpen"
              >{{
                selectUsers.length > 1
                  ? `${selectUsers.length} selecionados`
                  : `${selectUsers.length} selecionado`
              }}
            </span>
          </template>
          <span slot="noResult">Usuário não encontrado</span>
        </multiselect>
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="col-md-2 mb-1 form-group form-outline"
        v-slot="{ errors, ariaMsg, classes }"
        rules="required"
        name="Estado"
      >
        <label class="mb-1 label_default" :class="classes">UF</label>
        <div class="input_default" v-if="loadState">
          <div class="spinner">
            <half-circle-spinner
              :animation-duration="1000"
              :size="25"
              color="#f38235"
            />
          </div>
        </div>
        <div class="input-group" v-else>
          <select
            class="form-control input_default"
            :class="classes"
            :value="state"
            @change="getCidade($event.target.value)"
          >
            <option disabled />
            <option
              :value="item.ufCity"
              v-for="(item, index) in uf"
              :key="index"
              :selected="item.ufCity == state"
            >
              {{ item.ufCity }}
            </option>
          </select>
          <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="col-md-6 mb-1 form-group form-outline"
        v-slot="{ errors, ariaMsg, classes }"
        rules="required"
        name="Municipio"
      >
        <label class="mb-1 label_default" :class="classes">Município</label>
        <div class="input_default" v-if="loadCity">
          <div class="spinner">
            <half-circle-spinner
              :animation-duration="1000"
              :size="25"
              color="#f38235"
            />
          </div>
        </div>

        <div class="input-group" v-else>
          <select
            class="form-control input_default"
            :value="city"
            :class="classes"
            @input="$emit('update:city', $event.target.value)"
          >
            <option disabled :selected="city === ''" />
            <option
              :value="item.nameCity"
              v-for="(item, index) in cidades"
              :key="index"
              :selected="item.nameCity == city"
            >
              {{ item.nameCity }}</option
            >
          </select>
        </div>
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="col-md-4 mb-1 form-group form-outline"
        v-slot="{ errors, ariaMsg, classes }"
        rules="required"
        name="Inauguracao"
      >
        <label class="mb-1 label_default" :class="classes"
          >Data de Inauguração</label
        >
        <div class="input-group">
          <input
            type="date"
            class="form-control input_default"
            :class="classes"
            :value="openingDate"
            @change="$emit('update:openingDate', $event.target.value)"
          />
        </div>
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="col-md-6 mb-1 form-group form-outline"
        v-slot="{ errors, ariaMsg, classes }"
        name="Endereco"
        :rules="
          hasPublicityCard
            ? {
                required: true
              }
            : ''
        "
      >
        <label class="mb-1 label_default" :class="classes">Endereço</label>
        <input
          class="form-control input_default"
          :class="classes"
          :value="address"
          @input="$emit('update:address', $event.target.value)"
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        name="Número"
        class="col-md-2 mb-1 form-group form-outline"
        :rules="
          hasPublicityCard
            ? {
                required: true
              }
            : ''
        "
      >
        <label class="mb-1 label_default" :class="classes">Número</label>
        <input
          class="form-control input_default"
          :class="classes"
          :value="numberAddress"
          @input="$emit('update:numberAddress', $event.target.value)"
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        class="col-md-4 mb-1 form-group form-outline"
        v-slot="{ errors, ariaMsg, classes }"
        name="Bairro"
        :rules="
          hasPublicityCard
            ? {
                required: true
              }
            : ''
        "
      >
        <label class="mb-1 label_default" :class="classes">Bairro</label>
        <input
          class="form-control input_default"
          :class="classes"
          :value="district"
          @input="$emit('update:district', $event.target.value)"
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        name="E-mail"
        class="col-md-5 mb-1 form-group form-outline"
        :rules="
          hasPublicityCard
            ? {
                required: true,
                email: true
              }
            : {
                email: true
              }
        "
      >
        <label class="mb-1 label_default" :class="classes">E-mail</label>
        <input
          class="form-control input_default"
          :class="classes"
          :value="email"
          @input="$emit('update:email', $event.target.value)"
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <ValidationProvider
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        :rules="
          hasPublicityCard
            ? {
                required: true
              }
            : ''
        "
        name="Telefone"
        class="col-md-3 mb-1 form-group form-outline"
      >
        <label class="mb-1 label_default" :class="classes">Telefone</label>
        <the-mask
          type="text"
          class="form-control input_default"
          :class="classes"
          :value="cellphone"
          :mask="['(##) ####-####', '(##) #####-####']"
          @input.native="$emit('update:cellphone', $event.target.value)"
          :masked="false"
        />

        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>

      <div class="col-md-3 mb-1 form-group form-outline">
        <label class="mb-1 label_default">
          Divulgar Unidade
        </label>
        <div class="d-flex align-items-center mt-1">
          <div class="mr-2 field-checkbox">
            <input
              class="field"
              type="checkbox"
              id="publish"
              :checked="hasPublicityCard"
              :value="hasPublicityCard"
              @change="$emit('update:hasPublicityCard', $event.target.checked)"
            />
            <label class="description" for="publish"></label>
          </div>

          <p
            class="label_default _checkbox mb-2 list"
            @click="$emit('getCheckBox', $event.target)"
          >
            Sim
          </p>
        </div>
      </div>
      <ValidationProvider
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        name="Midia Social"
        class="col-md-5 mb-1 form-group form-outline"
        :rules="
          hasPublicityCard
            ? {
                required: true
              }
            : ''
        "
      >
        <label class="mb-1 label_default" :class="classes">Midia Social</label>
        <input
          class="form-control input_default"
          :class="classes"
          :value="socialMedia"
          @input="$emit('update:socialMedia', $event.target.value)"
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>

      <div class="col-md-12 mb-1 form-group form-outline">
        <label class="label_default label_blue">
          Modelo de negócio
        </label>
      </div>
      <div class="col-md-12 row">
        <div class="col-md-auto">
          <ValidationProvider
            tag="div"
            v-slot="{ errors, ariaMsg, classes }"
            name="Modelo de negócio"
            class="d-flex align-items-center form-check"
            :rules="
              hasOwnStore
                ? {
                    required: true
                  }
                : ''
            "
          >
            <div class="mr-2 field-checkbox">
              <input
                class="field"
                type="checkbox"
                id="inLotetica"
                :checked="!hasOwnStore"
                :value="!hasOwnStore"
                @change="
                  $emit(
                    'update:hasOwnStore',
                    $event.target.checked ? false : true
                  )
                "
              />
              <label class="description" for="inLotetica"></label>
            </div>
            <p
              class="label_default _checkbox mb-2 list"
              @click="$emit('getCheckBox', $event.target)"
            >
              Dentro da lotérica
            </p>
            <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-auto">
          <ValidationProvider
            tag="div"
            v-slot="{ errors, ariaMsg, classes }"
            name="Modelo de negócio"
            class="d-flex align-items-center form-check"
            :rules="
              !hasOwnStore
                ? {
                    required: true
                  }
                : ''
            "
          >
            <div class="mr-2 field-checkbox">
              <input
                class="field"
                type="checkbox"
                id="outLoterica"
                :checked="hasOwnStore"
                :value="hasOwnStore"
                @change="
                  $emit(
                    'update:hasOwnStore',
                    $event.target.checked ? true : false
                  )
                "
              />
              <label class="description" for="outLoterica"></label>
            </div>
            <p
              class="label_default _checkbox mb-2 list"
              @click="$emit('getCheckBox', $event.target)"
            >
              Fora da lotérica
            </p>
            <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";
import { TheMask } from "vue-the-mask";
import { mask } from "vue-the-mask";
import Multiselect from "vue-multiselect";
export default {
  props: [
    "id",
    "name",
    "reference",
    "userIds",
    "state",
    "city",
    "openingDate",
    "address",
    "numberAddress",
    "district",
    "socialMedia",
    "email",
    "cellphone",
    "hasPublicityCard",
    "hasOwnStore",
    "listUsers"
  ],
  directives: { mask },
  components: { HalfCircleSpinner, TheMask, Multiselect },
  data() {
    return {
      uf: [],
      cidades: [],
      loadCity: true,
      loadState: true,
      selectUsers: []
    };
  },
  created() {
    this.getEstado();
    this.setSelectUsers();
  },
  methods: {
    getCidade(uf) {
      this.$store
        .dispatch("getCity", { nameUf: uf })
        .then(response => {
          this.cidades = response;
          this.loadCity = false;
          if (this.state != uf) {
            //clear city where state !=
            this.$emit("update:city", "");
          }
          //update state
          this.$emit("update:state", uf);
        })
        .catch(error => {
          this.city = "";
          this.state = "";
        });
    },
    getEstado() {
      this.loadCity = true;
      this.$store
        .dispatch("getCity", { nameUf: "" })
        .then(response => {
          this.uf = response;
          if (this.state != "") {
            this.loadState = false;
            this.getCidade(this.state);
          } else {
            this.loadState = this.loadCity = false;
          }
        })
        .catch(error => {
          this.city = "";
          this.state = "";
        });
    },
    limitText(count) {
      return `e mais ${count}`;
    },
    addUser(element) {},
    setSelectUsers() {
      if (this.userIds) {
        this.userIds.forEach(userId => {
          this.listUsers.forEach(user => {
            if (user.userId == userId) {
              this.selectUsers.push(user);
            }
          });
        });
      }
    }
  },
  watch: {
    selectUsers: function() {
      let newArray = [];
      this.selectUsers.forEach(user => {
        newArray.push(user.userId);
      });
      this.$emit("update:userIds", newArray);
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
.multiselect.is-invalid,
.was-validated .form-control:invalid {
  border: 2px solid;
  border-color: #dc3545;
}
.multiselect.is-valid,
.was-validated .form-control:valid {
  border: 2px solid;
  border-color: #28a745 !important;
}

.multiselect {
  border: 1px solid #98c4e6;
  border-radius: 6px !important;
  height: 40px !important;
  box-shadow: none;
}
</style>
