<template>
  <div>
    <div class="container" v-if="progressGet <= 100">
      <p class="create" v-if="edit">Editando Unidade...</p>
      <p class="create" v-else>Criando Unidade...</p>
      <div class="progress">
        <div
          v-if="progressGet <= 100 && progressGet != 0.1"
          class="progress-bar color"
          role="progressbar"
          :style="`width: ${progressGet}%;`"
          :aria-valuenow="progressGet"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ progressGet }}%
        </div>
        <div v-else-if="progressGet === 0.1">
          <p>Algo deu errado</p>
        </div>
      </div>
    </div>

    <div class="container" v-else>
      <p class="create mb-0">{{ progressGet }}</p>
    </div>
  </div>
</template>

<script>
import store from "../../../../../store";
import { mapGetters } from 'vuex'
export default {
  computed: {
    progressGet() {
      return store.getters.progressGet;
    },
    edit() {
      return store.getters.editGet;
    },
  }
};
</script>

<style scoped>
.create {
  font-size: 12px;
  color: #fff;
  margin-top: 7px;
}

.color{
  background: #e0a638 !important;
}
p {
  color: #000;
}
</style>
