<template>
  <div
    class="tab-pane fade col-md-12"
    id="tenantgoal"
    role="tabpanel"
    aria-labelledby="tenantgoal-tab`"
  >
    <div class="row">
      <ValidationProvider
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        :rules="{
          required: true
        }"
        class="col-md-5 mb-1 form-group form-outline"
        name="Meta da Unidade"
        :mode="custom"
      >
        <label class="mb-1 label_default" :class="classes"
          >Meta da Unidade</label
        >
        <money
          v-model="meta"
          v-bind="money"
          class="input_default form-control"
          :class="classes"
          maxlength="12"
        />
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>

      <ValidationProvider
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        class="col-md-4 mb-1 form-group form-outline"
        name="Perfil"
        :rules="{
          required: true
        }"
      >
        <label class="mb-1 label_default" :class="classes">Perfil</label>
        <div class="input-group">
          <select
            @change="
              suggestionGoal($event.target.value),
                $emit('update:port', $event.target.value)
            "
            :value="port"
            class="form-control input_default"
            :class="classes"
          >
            <option disabled />
            <option
              :value="item.idPerfil"
              v-for="(item, index) in perfil"
              :key="index"
              >{{ item.perfil }}</option
            >
          </select>
        </div>
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
      <div class="col-md-3 form-group form-outline">
        <i
          class="fas fa-info-circle mt-4"
          data-toggle="tooltip"
          data-placement="right"
          title="Selecionando um 'Perfil' será sugerido um valor de Meta da Unidade"
        >
        </i>
      </div>
      <ValidationProvider
        tag="div"
        v-slot="{ errors, ariaMsg, classes }"
        class="col-md-7 mb-1 form-group form-outline"
        name="Quantidade de dias"
        :rules="{
          required: !(goal == '' || goal == '0')
        }"
      >
        <label class="mb-1 label_default" :class="classes"
          >Quantidade de dias</label
        >
        <div class="input-group">
          <select
            class="form-control input_default"
            :class="classes"
            :value="goalDuration"
            @change="$emit('update:goalDuration', $event.target.value)"
          >
            <option :value="index" v-for="index in 28" :key="index">{{
              index
            }}</option>
          </select>
        </div>
        <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
import { Money } from "v-money";
export default {
  props: ["port", "goal", "goalDuration", "daysForCalculate"],
  components: {
    Money
  },
  data() {
    return {
      perfil: [
        { perfil: "P", idPerfil: 1 },
        { perfil: "M", idPerfil: 2 },
        { perfil: "G", idPerfil: 3 },
        { perfil: "GG", idPerfil: 4 }
      ],
      meta: this.goal,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false
      }
    };
  },
  mounted() {
    this.enableTooltip();
  },
  methods: {
    enableTooltip() {
      $(function() {
        $('[data-toggle="tooltip"]').tooltip();
      });
    },
    suggestionGoal(val) {
      switch (val) {
        case "1":
          this.meta = "2000,00";
          break;
        case "2":
          this.meta = "2500,00";
          break;
        case "3":
          this.meta = "3000,00";
          break;
        case "4":
          this.meta = "4000,00";
          break;
      }
    },
    custom() {
      return { on: ["blur"], debounce: 1000 };
    }
  },
  watch: {
    meta: function() {
      this.$emit("update:goal", this.getValueBR(this.meta.toString()));
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
